


























































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Card } from '@/types/cms/components';
import { handleFile } from '@/utils/cms/formhelper';
import AlertModule, { AlertType } from '@/store/modules/alert';

/**
 * PageCardFormDialog component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class HomePageCardFormDialog extends Vue {
    // Prop of type boolean that defined whether the dialog should be shown or not
    @Prop(Boolean) readonly showDialog: boolean | undefined;

    // Prop that holds the pageCard data
    @Prop({type: Object as () => Card}) readonly editedPageCardItem: Card | undefined;

    // Prop that holds the id of the edited pageCard
    @Prop(Number) readonly editedPageCardIndex: number | undefined;

    // An crud string that defines the mode of the dialog
    @Prop(String) readonly dialogMode: string | undefined;

    // Boolean that defines wheter the form data is valid or not
    private formValid = false;

    /**
     * @returns the title for the form
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get formTitle(): string {
        return (this.dialogMode) ? this.$t('form.titles.' + this.dialogMode + 'PageCard').toString() : '';
    }

    /**
     * Resets the form validation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private resetFormValidation() {
        if (this.$refs.form) {
            (this.$refs.form as Vue & { resetValidation: () => void }).resetValidation();
        }
    }

    /**
     * Saves/Updates or deletes the pageCard
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private savePageCard() {
        if (!this.editedPageCardItem) return;

        handleFile(
            this.dialogMode,
            this.editedPageCardItem.image.src,
            this.editedPageCardItem.image.obj
        )
            .then(fileResponse => {
                if (
                    fileResponse.data.status !== 'success' ||
                    !('fileUrl' in fileResponse.data.data) ||
                    !this.editedPageCardItem?.image
                ) {
                    const errorMessage = this.$t('alerts.error.' + fileResponse.data.message).toString();
                    AlertModule.showAlert({
                        type: AlertType.ERROR,
                        message: errorMessage
                    });
                    return;
                }

                this.resetFormValidation();
                this.$emit(
                    'pageCardSaved', 
                    this.dialogMode, 
                    this.editedPageCardIndex, 
                    { 
                        ...this.editedPageCardItem,
                        image: {
                            ...this.editedPageCardItem.image,
                            src: fileResponse.data.data.fileUrl
                        }
                    }
                );
                return;
            });

        this.resetFormValidation();
        this.$emit('pageCardSaved', this.dialogMode, this.editedPageCardIndex, this.editedPageCardItem);
    }

    /**
     * Closes the dialog
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private closeDialog() {
        this.resetFormValidation();
        this.$emit('dialogClosed');
    }
}
